import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";

import PageTitle from "../../../layouts/PageTitle";
import {add_user_api, get_admin_user} from "../../../../api/secure"
import {handleFormInput} from "../../../../common/form"
import TableView from "./TableView";

const AdminList = ({history}) => {
    const [formData, setFormData] = useState({});
    const [users, setUsers] = useState([]);
    const handleBlur = (e, payload = {}) => {
        let ct = handleFormInput({e, formData: formData, payload});
        console.log("ct", ct);
        setFormData(ct);
    };
    const submitHandler = async (e) => {
        e.preventDefault();
        //history.push("/");
        await add_user_api({formData});
    };

    const getAllUser = async () => {
        const dt = await get_admin_user();
        console.log("dt", dt);
        setUsers(dt);
    };

    useEffect(() => {
        getAllUser();
    }, []);

    return (
        <>
            <PageTitle activeMenu="Admin" motherMenu="User"/>
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Add User</h4>
                    </div>
                    <div className="card-body">
                        <form
                            action={""}
                            onSubmit={(e) => submitHandler(e)}
                        >
                            <input type="hidden" name="collectionName" value="ChargerType"/>
                            <div className="row">
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">User Email*</label>
                                        <input
                                            type="text"
                                            name="email"
                                            className="form-control"
                                            placeholder="Eamil"
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Password*</label>
                                        <input
                                            onChange={handleBlur}
                                            type="password"
                                            name="password"
                                            className="form-control"
                                            placeholder="Password"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">User Type*</label>
                                        <select
                                            onChange={handleBlur}
                                            name="userType"
                                            defaultValue={''}
                                            className="form-control"
                                            required
                                        >
                                            <option value="">Select type</option>
                                            <option value="tl">Team Leader</option>
                                            <option value="ot">Other</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-12 mb-2">
                                    <button type='submit' className='btn btn-primary mb-2 float-right'>
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="row">
                {users.length > 0 &&
                    <TableView
                        title={"Users"}
                        reloadDB={() => getAllUser()}
                        data={users}
                        actionShow={false}
                    />
                }
            </div>
        </>
    );
};

export default AdminList;
