import React, {useState} from 'react';
import {Tab, Nav, Button} from 'react-bootstrap';
import GuideCard from '../../../../common/cards/GuideCard.js';

let renderGuideCard = () => {
    return(
        <GuideCard
            dsc={"Exciting news! This section is currently under development. Stay tuned as we prepare to unveil it soon. Get ready to access and start using this new section in no time. We can't wait to share it with you!"}
            buttonText={"Add member"}
        />
    )
}

const ReusableComponent = ({title, description, tabConfig}) => {
    const [defaultActiveHead, setDefaultActiveHead] = useState('0');
    const [defaultActiveSubKey, setDefaultActiveSubKey] = useState('0');

    const customToggle = (key) => {
        setDefaultActiveSubKey(key);
    };

    const renderHeader = (index, name) => {
        return (
            <span>
        {index + 1}. {name}
      </span>
        );
    };

    const renderSubHeader = (index, name) => {
        return (
            <span>
        {String.fromCharCode(65 + index)}. {name}
      </span>
        );
    };

    const handlePrevious = () => {
        if (defaultActiveHead !== null && defaultActiveSubKey !== null) {
            const activeHead = parseInt(defaultActiveHead);
            const activeSubKey = parseInt(defaultActiveSubKey);
            if (activeSubKey > 0) {
                setDefaultActiveSubKey((activeSubKey - 1).toString());
            } else {
                if (activeHead > 0) {
                    const previousHead = tabConfig[activeHead - 1];
                    const previousSubTabs = previousHead.data;
                    setDefaultActiveHead((activeHead - 1).toString());
                    setDefaultActiveSubKey((previousSubTabs.length - 1).toString());
                }
            }
        }
    };

    const handleNext = () => {
        if (defaultActiveHead !== null && defaultActiveSubKey !== null) {
            const activeHead = parseInt(defaultActiveHead);
            const activeSubKey = parseInt(defaultActiveSubKey);
            const currentHead = tabConfig[activeHead];
            const subTabs = currentHead.data;
            if (activeSubKey < subTabs.length - 1) {
                setDefaultActiveSubKey((activeSubKey + 1).toString());
            } else {
                if (activeHead < tabConfig.length - 1) {
                    setDefaultActiveHead((activeHead + 1).toString());
                    setDefaultActiveSubKey('0');
                }
            }
        }
    };

    const renderPrevious = (i, ii) => {
        if (i === 0 && ii === 0) {
            return false;
        } else {
            return (
                <Button variant="primary" onClick={handlePrevious}>
                    Previous
                </Button>
            );
        }
    };

    const renderNext = (i, tableLength, ii, subLength) => {
        if (i === tableLength - 1) {
            if (ii === subLength - 1) {
                return false;
            } else {
                return (
                    <Button variant="primary" onClick={handleNext}>
                        Next
                    </Button>
                )
            }
        } else {
            return (
                <Button variant="primary" onClick={handleNext}>
                    Next
                </Button>
            )
        }
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-xxl-12">
                {/*<a className="btn btn-warning btn-rounded mb-4" href="javascript:void()">
                    <i className="las la-long-arrow-alt-left mr-4"/>
                    Back
                </a>*/}
                <div className="card">
                    <div className="card-header">
                        <div>
                            <h4 className="card-title">
                                {title}
                            </h4>
                            <p>
                                {description}
                            </p>
                        </div>
                    </div>
                    <div className="card-body">
                        <Tab.Container defaultActiveKey={defaultActiveHead || '0'} activeKey={defaultActiveHead || '0'}>
                            <Nav as="ul" className="nav-pills mb-4 justify-content-start">
                                {tabConfig.map((data, i) => (
                                    <Nav.Item as="li" key={i}>
                                        <Nav.Link eventKey={`${i}`} onClick={() => setDefaultActiveHead(String(i))}>
                                            {renderHeader(i, data.tabName)}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                            <Tab.Content>
                                {tabConfig.map((data, i) => (
                                    <Tab.Pane eventKey={`${i}`} key={i}>
                                        <div className="custom-tab-1 mb-4">
                                            <Tab.Container
                                                activeKey={defaultActiveSubKey || '0'}
                                                defaultActiveKey={defaultActiveSubKey || '0'}
                                            >
                                                <Nav as="ul" className="nav-tabs">
                                                    {data.data.map((data_t, ii) => (
                                                        <Nav.Item as="li" key={ii}>
                                                            <Nav.Link onClick={() => customToggle(String(ii))}
                                                                      eventKey={`${ii}`}>
                                                                {renderSubHeader(ii, data_t.tabName)}
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ))}
                                                </Nav>

                                                <Tab.Content className="pt-4">
                                                    {data.data.map((data_t, ii) => {
                                                        let Comp = data_t.component;
                                                        return(
                                                            <Tab.Pane eventKey={`${ii}`} key={ii}>
                                                                {/* Render your component here */}


                                                                {typeof data_t.component === 'string' ? (
                                                                    renderGuideCard()
                                                                ) : (
                                                                    <Comp/>
                                                                )}

                                                                <div className="d-flex justify-content-between">
                                                                    {renderPrevious(i, ii) ? renderPrevious(i, ii) : (
                                                                        <div></div>)}
                                                                    {renderNext(i, tabConfig.length, ii, data.data.length)}
                                                                </div>
                                                            </Tab.Pane>
                                                        )
                                                    })}
                                                </Tab.Content>
                                            </Tab.Container>
                                        </div>
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReusableComponent;


