import React from "react";
import {Link} from "react-router-dom";
import profile from "../../images/rfb/notFound.png";
import {Button} from "react-bootstrap";

const GuideCard = ({dsc, imageWidth, fullWidth, hideImage, title}) => {

    let image = profile;
    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row justify-content-center h-100 align-items-center mt-4">
                            <div className={fullWidth ? "col-12 text-center": "col-6 text-center"}>
                                { title &&
                                    <h2 className="text-success">{title}</h2>
                                }
                                {
                                    !hideImage &&
                                    <img src={image} style={{maxWidth: imageWidth ? imageWidth : 400}}/>
                                }
                                <br/>
                                <p className="text-center mt-4">
                                    {dsc}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GuideCard;
