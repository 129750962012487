import React from "react";
import { Table } from "react-bootstrap";
import Moment from "moment";

const columns = ["Module Flag", "Module Name", "Created Date"];

const TableView = ({ title, data, reloadDB }) => {
    const handleDelete = async (submoduleId) => {
        // Handle the delete action here
    };

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{title}</h4>
                </div>
                <div className="card-body">
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                                <thead>
                                <tr role="row">
                                    <th>SR.</th>
                                    {columns.map((d, i) => (
                                        <th key={i}>{d}</th>
                                    ))}
                                    {/*<th>Actions</th>*/}
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((d, i) => (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{d.moduleFlag}</td>
                                        <td>{d.moduleName}</td>
                                        <td>{Moment(d.created_date).locale("en").format("DD/MM/YYYY")}</td>
                                        {/*<td>
                                            <button
                                                className="btn btn-danger btn-sm"
                                                onClick={() => handleDelete(d._id)}
                                            >
                                                Delete
                                            </button>
                                        </td>*/}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default TableView;
