import React, {useState} from 'react';
// import axios from 'axios';


/*{
    fieldName,
        labelName,
        placeholder,
        fieldType,
        onChange,
        dynamicValue,
        regex,
        className,
        style,
}*/

const FormComponent = ({
                           formData,
                           apiEndPoint,
                           onSubmit,
                           onFailed,
                           submitButtonText,
                       }) => {
    const [fieldValue, setFieldValue] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const {value} = e.target;
        setFieldValue(value);
        //if (onChange) onChange(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        alert('Submit Clicked');
    };

    const renderField = (item) => {
        const {
            fieldName,
            labelName,
            placeholder,
            fieldType,
            onChange,
            dynamicValue,
            regex,
            className,
            style,
            options
        } = item;

        if (fieldType === 'text') {
            return (
                <div className="col-lg-6 mb-2">
                    <div className="form-group text-left">
                        <label className="text-label">
                            {labelName}
                            {regex && '*'}
                        </label>
                        <input
                            onChange={handleChange}
                            type="text"
                            name={fieldName}
                            className="form-control"
                            placeholder={placeholder}
                            required
                        />
                    </div>
                </div>
            );
        } else if (fieldType === 'select') {
            return (
                <div className="col-lg-6 mb-2">
                    <div className="form-group text-left">
                        <label className="text-label">
                            {labelName}
                            {regex && '*'}
                        </label>
                        <select
                            className="form-control"
                            id="inputState"
                            name={fieldName}
                            defaultValue="option-1"
                            onChange={handleChange}
                        >
                            <option value="option-1">Choose...</option>
                            {
                                options?.map((op) => (
                                        <option value={String(op.value)}>{op.text}</option>
                                    )
                                )
                            }
                            {/* Add dynamic and customData options based on your requirements */}
                        </select>
                    </div>
                </div>
            );
        } else if (fieldType === 'file') {
            return (
                <div className="col-lg-6 mb-2">
                    <div className="form-group text-left">
                        <label className="text-label">
                            {labelName}
                            {regex && '*'}
                        </label>
                        <input
                            type="file"
                            name={fieldName}
                            className="form-control"
                            placeholder={placeholder}
                            required
                            onChange={handleChange}
                        />
                    </div>
                </div>
            );
        } else if (fieldType === 'dateTime') {
            return (
                <div className="col-lg-6 mb-2">
                    <div className="form-group text-left">
                        <label className="text-label">
                            {labelName}
                            {regex && '*'}
                        </label>
                        {/* Add your DateTime component here */}
                    </div>
                </div>
            );
        }
    };

    return (

        <form onSubmit={handleSubmit}>
            <div className="row">
                {
                    formData.map((item) => {
                        return renderField(item)
                    })
                }

                {error && <span>{error}</span>}
                <div className="col-lg-12 mb-2">
                    <button className="btn btn-primary mb-2 float-right"
                            type="submit">{submitButtonText ? submitButtonText : "Submit"}</button>
                </div>
            </div>
        </form>
    );
};

export default FormComponent;
