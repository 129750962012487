import React, {useRef, useState} from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {Badge, Pagination, Table} from "react-bootstrap";

const TableView = ({ tableData }) => {
    const [data, setData] = useState(tableData);


    const onDragEnd = (result) => {
        // Check if the drop was successful
        if (!result.destination) {
            return;
        }

        // Reorder the data based on the drag and drop result
        const updatedData = Array.from(data);
        const [movedItem] = updatedData.splice(result.source.index, 1);
        updatedData.splice(result.destination.index, 0, movedItem);
        // Update the state or perform any other necessary actions
        // with the reordered data
        setData(updatedData);
    };

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Table Title</h4>
                    {/* Add your search input code here */}
                    <div>
                        {/* Add your download button code here */}
                    </div>
                </div>
                <div className="card-body">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="table-rows">
                            {(provided) => (
                                <div
                                    id="example_wrapper"
                                    className="dataTables_wrapper"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <table id="example" className="display w-100 dataTable">
                                        <thead>
                                        <tr role="row">
                                            <th>Sr.</th>
                                            <th>Clm Name</th>
                                            <th>Clm Name</th>
                                            <th>Clm Name</th>
                                            <th>Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data.map((d, i) => (
                                            <Draggable key={i} draggableId={`row-${i}`} index={i}>
                                                {(provided) => (
                                                    <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <td>{i + 1}</td>
                                                        <td>{d.col}</td>
                                                        <td>{d.colTwo}</td>
                                                        <td>{d.colThree}</td>
                                                        <td>
                                                            <Badge variant="success light cursor-pointer">
                                                                Active
                                                            </Badge>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </div>
    );
};

export default TableView;