import React, {useState} from 'react';
import GuideCard from '../../../../common/cards/GuideCard';
import ReusableComponent from './ReusableComponent';

let renderGuideCard = () => {
    return(
        <GuideCard
            dsc={"Exciting news! This section is currently under development. Stay tuned as we prepare to unveil it soon. Get ready to access and start using this new section in no time. We can't wait to share it with you!"}
            buttonText={"Add member"}
        />
    )
}

const tabConfig = [
    {
        "tabName": "Security Shield",
        "nested": true,
        "data": [
            {
                "tabName": "IP Security",
                "title": "Adding Branches to CRM",
                "desc": "Short description of this page",
                "component": "IpSecurity"
            },
            {
                "tabName": "PasswordReset",
                "title": "Cancellation Reason",
                "desc": "Short description of this page",
                "component": "CancelReason"
            },
            {
                "tabName": "2FALogin",
                "title": "Case Abandoned Reason",
                "desc": "Short description of this page",
                "component": "2FALogin"
            },
            {
                "tabName": "AccessRest",
                "title": "Case Abandoned Reason",
                "desc": "Short description of this page",
                "component": "AccessRest"
            },
            {
                "tabName": "LoginIssues",
                "title": "Case Abandoned Reason",
                "desc": "Short description of this page",
                "component": "CaseAbandoned"
            },
        ]
    },
    {
        "tabName": "Security Fortress",
        "nested": false,
        "data": [
            {
                "tabName": "UserDetails",
                "title": "Managing Action Note Types in CRM",
                "desc": "Short description of this page",
                "component": "ActionNotes"
            },
            {
                "tabName": "UserTeams",
                "title": "Case Abandoned Reason",
                "desc": "Short description of this page",
                "component": "UserTeams"
            }
        ]
    }
    // Add more sections here following the same format
];

const Security = () => {
    return(
        <ReusableComponent
            title="Security"
            description="Comprehensive tools for managing client relations, action notes, cancellations, and case statuses."
            tabConfig={tabConfig}
        />
    )
};

export default Security;


