const logout = () => {
    localStorage.clear();
    window.location.href = '/';
}

const postData = async (url, data) => {
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Methods': 'POST',
            'authorization': window.localStorage.getItem('token'),
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((response) => {
            if (response?.message === "Invalid token") {
                logout();
            }else{
                //console.log(responseData);
                return response;
            }
        }).catch(function (error) {
            console.log('There has been a problem with your fetch operation: ' + error.message);
            return error;
            //logout();
            // ADD THIS THROW error
            //throw error;
        });
}

const getData = async (url) => {
    return await fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Methods': 'GET',
            'authorization': window.localStorage.getItem('token'),
        }
    })
        .then((response) => response.json())
        .then((response) => {
            if (response?.message === "Invalid token") {
                logout();
            }else{
                //console.log(responseData);
                return response;
            }
        })
        .catch(function (error) {
            console.log('There has been a problem with your fetch operation: ' + error.message);
            return error;
            //logout();
            // ADD THIS THROW error
            //throw error;
        });
}

export {postData, getData, logout};
