import {postData, getData} from "../../import";
import swal from "sweetalert";

let url;
if (process.env.REACT_APP_ENV === "development") {
    url = process.env.REACT_APP_DEV_API;
} else {
    url = process.env.REACT_APP_PRO_API;
}

const register_api = async (registerData, history) => {
    console.log("registerData", registerData);
    const {email, password} = {...registerData};
    const data = {
        email: email,
        password: password
    };

    if (email && password) {
        //const registerRes = await postData(url + 'signup', data);
        const registerRes = await postData(url + 'auth/signup', data);

        if (registerRes.message === "User created successfully") {
            await swal(
                "Warning!",
                "Registration successful please login with given email & password",
                "success"
            )
            history.push("/");
            return true;
        } else {
            await swal(
                "Warning!",
                registerRes.message,
                "info"
            )
            const newRegistrationData = {...registerData};
            newRegistrationData["registration_fail"] = true;
            newRegistrationData["msg"] = registerRes.message;
            return newRegistrationData
        }
    } else {
        await swal(
            "Warning!",
            "All fields are required",
            "info"
        )
    }
}

const login_api = async ({loginData}) => {
    const {email, password} = {...loginData};
    //let data : {email: any, password: any} = {"email": loginData.email, "password": loginData.password};
    const data = {email: email, password: password};
    if (email && password) {
        const loginRes = await postData(url + 'auth/login', data);
        if (loginRes.message === 'Login successful') {
            const {token, user_name, user_type} = {...loginRes};
            window.localStorage.setItem('token', token);
            // window.localStorage.setItem('user_name', JSON.stringify(user_name));
            // window.localStorage.setItem('user_type', JSON.stringify(user_type));
            window.location.href = "/";
            window.location.reload();
            return true;

            //this.props.history.push('/');
            //history.push("/");
        } else {
            //alert(loginRes.message);
            const newLoginData = {...loginData};
            newLoginData["login_fail"] = true;
            newLoginData["msg"] = loginRes.message;
            await swal(
                "Warning!",
                loginRes.message,
                "info"
            )
            return newLoginData
        }
    } else {
        await swal(
            "Warning!",
            "Email and password is mandatory",
            "info"
        )
    }
}

const change_password_api = async ({loginData}) => {
    const {currentPassword, newPassword, confirmPassword} = {...loginData};
    //let data : {email: any, password: any} = {"email": loginData.email, "password": loginData.password};
    const data = {currentPassword, newPassword};
    if (currentPassword && newPassword && confirmPassword) {
        if (newPassword !== confirmPassword) {
            await swal(
                "Warning!",
                "New password not match with confirm password",
                "info"
            );
            return true;
        }
        const loginRes = await postData(url + 'auth/change-password', data);
        if (loginRes.message === 'Password changed successfully') {
            localStorage.clear();
            window.location.href = '/';
            return true;

            //this.props.history.push('/');
            //history.push("/");
        } else {
            //alert(loginRes.message);
            const newLoginData = {...loginData};
            newLoginData["login_fail"] = true;
            newLoginData["msg"] = loginRes.message;
            await swal(
                "Warning!",
                loginRes.message,
                "info"
            )
            return newLoginData
        }
    } else {
        await swal(
            "Warning!",
            "Email and password is mandatory",
            "info"
        )
    }
}

const add_user_api = async ({formData}) => {
    console.log("registerData", formData);
    const {email, password, userType} = {...formData};
    const data = {
        email: email,
        password: password,
        userType: userType
    };

    if (email && password && userType) {
        //const registerRes = await postData(url + 'signup', data);
        const registerRes = await postData(url + 'auth/add-user', data);

        if (registerRes.message === "User created successfully") {
            await swal(
                "Warning!",
                "User Add Successfully",
                "success"
            );
            return true;
        } else {
            await swal(
                "Warning!",
                registerRes.message,
                "info"
            )
            const newRegistrationData = {...formData};
            newRegistrationData["registration_fail"] = true;
            newRegistrationData["msg"] = registerRes.message;
            return newRegistrationData
        }
    } else {
        await swal(
            "Warning!",
            "All fields are required",
            "info"
        )
    }
}

const get_admin_user = async () => {
     return await getData(url + 'auth/get-users');
}

export {login_api, register_api, change_password_api, add_user_api, get_admin_user, url};