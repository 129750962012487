import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
// import drump from "../../../images/card/drump.png";

class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      //  this.mm("dispose");
      // console.log(this.mm);
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   /// Open menu
    componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);
	  
	  
	  //sidebar icon Heart blast
	  var handleheartBlast = document.querySelector('.heart');

        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }

        handleheartBlast.addEventListener('click', heartBlast);
   }
   render() {
      /// Path
      let path = window.location.pathname;
      path = path.split("/");
      path = path[path.length - 1];

      const sideBarData = [
         {
            name: 'Dashboard',
            url: '/',
            icon: 'flaticon-381-networking',
            nested: false
         },
         {
            name: 'Admin Users',
            url: '/admin-list',
            icon: 'fa fa-user',
            nested: false
         },
         {
            name: 'Modules',
            url: '/module-list',
            icon: 'fa fa-user',
            nested: false
         },
         {
            name: 'Sub Modules',
            url: '/sub-module-list',
            icon: 'fa fa-user',
            nested: false
         },
         {
            name: 'System Settings',
            url: '/sub-module-list',
            icon: 'flaticon-381-networking',
            nested: true,
            data: [
               {
                  name: 'General',
                  url: '/ss-general',
               },
               {
                  name: 'Communication',
                  url: '/ss-communication',
               },
               {
                  name: 'Data Management',
                  url: '/ss-data-management',
               },
               {
                  name: 'Security',
                  url: '/ss-security',
               }
            ]
         },
         {
            name: 'Setting',
            url: '/change-password',
            icon: 'fa fa-cog',
            nested: false
         }
      ];

      const logout = () => {
         localStorage.clear();
         window.location.href = '/';
         //window.location.reload();
         //this.props.history.push('/');
      }

      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">
                  {
                     sideBarData.map((item, i) => {
                        if (item.nested) {
                           return (
                               <li className={`${path === item.url ? "mm-active" : ""}`}>
                                  <Link
                                      className="has-arrow ai-icon"
                                      to={item.url}
                                      aria-expanded="false"
                                  >
                                     <i className={item.icon}></i>
                                     <span className="nav-text">{item.name}</span>
                                  </Link>
                                  {
                                      item.data.length > 0 &&
                                      <ul aria-expanded="false">
                                         {
                                            item.data.map((d, i) => (
                                                <li>
                                                   <Link
                                                       className={`${path === item.url ? "mm-active" : ""}`}
                                                       onClick={() => this.props.onClick()}
                                                       to={d.url}>
                                                      {d.name}
                                                   </Link>
                                                </li>
                                            ))
                                         }
                                      </ul>
                                  }
                               </li>
                           )
                        } else {
                           return (
                               <li className={`${path === item.url ? "mm-active" : ""}`}>
                                  <Link
                                      className="ai-icon"
                                      to={item.url}
                                      aria-expanded="false"
                                  >
                                     <i className={item.icon}></i>
                                     <span className="nav-text">{item.name}</span>
                                  </Link>
                               </li>
                           )
                        }
                     })
                  }

                  <li className={""}>
                     <a
                         onClick={logout.bind()}
                         href="JavaScript:void()"
                         className="ai-icon"
                         aria-expanded="false"
                     >
                        <i className="fa fa-power-off"></i>
                        <span className="nav-text">Logout</span>
                     </a>
                  </li>
               </MM>

               <div className="copyright">
                  <p className="fs-14 font-w200">
                     <strong className="font-w400">
                        Oarlock Ticketing Admin
                     </strong>{" "}
                     Dashboard © 2023 All Rights Reserved
                  </p>
                  <p>
                     Made with <span class="heart"></span> by Oarlock
                  </p>
               </div>
            </PerfectScrollbar>
         </div>
      );
   }
}

export default SideBar;
