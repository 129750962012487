
const handleFormInput = ({e, formData: formData = {}, payload: payload = {}}) => {
    const { valType } = payload;
    const formDataLocal = {...formData};
    let value;
    if (valType === "lowerCase"){
        value = e.target.value.toLowerCase();
    }else{
        value = e.target.value;
    }
    formDataLocal[e.target.name] = value;
    //console.log(newLoginData);
    return formDataLocal;
};

export { handleFormInput };