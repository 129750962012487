import React, {useEffect, useState} from "react";
import PageTitle from "../../../../layouts/PageTitle";
import {add_submodule_api, get_submodules_api, get_modules_api} from "../../../../../api/modules";
import { handleFormInput } from "../../../../../common/form";
import TableView from "./TableView";

const Submodules = ({ history }) => {
    const [formData, setFormData] = useState({});
    const [modules, setModules] = useState([]);
    const [submodules, setSubmodules] = useState([]);

    const handleBlur = async (e, payload = {}) => {
        let updatedFormData = handleFormInput({ e, formData: formData, payload });
        setFormData(updatedFormData);if (e.target.name === "moduleId"){
            await handleModuleChange(e.target.value);
        }

    };

    const submitHandler = async (e) => {
        e.preventDefault();
        await add_submodule_api(formData);
        await handleModuleChange(formData.moduleId);
    };

    const handleModuleChange = async (moduleId) => {
        console.log("moduleId", moduleId);
        try {
            const submodulesData = await get_submodules_api(moduleId);
            console.log("submodulesData", submodulesData);

            setSubmodules(submodulesData);
        } catch (error) {
            console.error("Error fetching submodules:", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const modulesData = await get_modules_api();
                setModules(modulesData);

                const submodulesData = await get_submodules_api();
                setSubmodules(submodulesData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <PageTitle activeMenu="Admin" motherMenu="Submodules" />
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Add Submodule</h4>
                    </div>
                    <div className="card-body">
                        <form action={""} onSubmit={submitHandler}>
                            <div className="row">
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="moduleId" className="text-label">Module:</label>
                                        <select
                                            id="moduleId"
                                            name="moduleId"
                                            className="form-control"
                                            value={formData.moduleId || ""}
                                            onChange={handleBlur}
                                            required
                                        >
                                            <option value="">Select Module</option>
                                            {modules.map((module) => (
                                                <option key={module._id} value={module._id}>
                                                    {module.moduleName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Submodule Name*</label>
                                        <input
                                            type="text"
                                            name="moduleName"
                                            className="form-control"
                                            placeholder="Submodule Name"
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Submodule Flag*</label>
                                        <input
                                            type="text"
                                            name="moduleFlag"
                                            className="form-control"
                                            placeholder="Submodule Name"
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 mb-2">
                                    <button type="submit" className="btn btn-primary mb-2 float-right">
                                        Add
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="row">
                {submodules.length > 0 && (
                    <TableView title={"Submodules"} data={submodules} />
                )}
            </div>
        </>
    );
};

export default Submodules;
