import React, {useState} from 'react';
import GuideCard from '../../../../common/cards/GuideCard';
import ReusableComponent from './ReusableComponent';
import Branches from './general/crm/Branches';
import ActionNotes from './general/crm/ActionNotes';
import CancelReason from './general/crm/CancelReason';
import CaseAbandoned from './general/crm/CaseAbandoned';

let renderGuideCard = () => {
    return(
        <GuideCard
            dsc={"Exciting news! This section is currently under development. Stay tuned as we prepare to unveil it soon. Get ready to access and start using this new section in no time. We can't wait to share it with you!"}
            buttonText={"Add member"}
        />
    )
}

const tabConfig = [
    {
        "tabName": "CRM Basic",
        "nested": true,
        "data": [
            {
                "tabName": "Branches",
                "title": "Adding Branches to CRM",
                "desc": "Short description of this page",
                "component": Branches
            },
            {
                "tabName": "ActionNotes",
                "title": "Managing Action Note Types in CRM",
                "desc": "Short description of this page",
                "component": ActionNotes
            },
            {
                "tabName": "CancelReason",
                "title": "Cancellation Reason",
                "desc": "Short description of this page",
                "component": CancelReason
            },
            {
                "tabName": "CaseAbandoned",
                "title": "Case Abandoned Reason",
                "desc": "Short description of this page",
                "component": CaseAbandoned
            }
        ]
    },
    {
        "tabName": "Profile & Billing",
        "nested": false,
        "data": [
            {
                "tabName": "UserEdit",
                "title": "User Profile Edit",
                "desc": "Short description of this page",
                "component": "UserEdit"
            },
            {
                "tabName": "SMSInvoices",
                "title": "SMS Invoices",
                "desc": "Short description of this page",
                "component": "SMSInvoices"
            },
            {
                "tabName": "NewUser",
                "title": "New User Setup",
                "desc": "Short description of this page",
                "component": "NewUser"
            }
        ]
    },
    {
        "tabName": "Task & Stage",
        "nested": false,
        "data": [
            {
                "tabName": "SimpleTasks",
                "title": "Simple Tasks Setup",
                "desc": "Short description of this page",
                "component": "SimpleTasks"
            },
            {
                "tabName": "NewStage",
                "title": "New Stage",
                "desc": "Short description of this page",
                "component": "NewStage"
            },
            {
                "tabName": "StageActions",
                "title": "Stage Actions",
                "desc": "Short description of this page",
                "component": "StageActions"
            },
            {
                "tabName": "Criteria",
                "title": "Criteria for Moving to a Stage",
                "desc": "Short description of this page",
                "component": "Criteria"
            },
            {
                "tabName": "TaskStatuses",
                "title": "Task Statuses",
                "desc": "Short description of this page",
                "component": "TaskStatuses"
            }
        ]
    },
    // Add more sections here following the same format
];

const General = () => {
    return(
        <ReusableComponent
            title="CRM Management."
            description="Comprehensive tools for managing client relations, action notes, cancellations, and case statuses."
            tabConfig={tabConfig}
        />
    )
};

export default General;


