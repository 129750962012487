import React, {useState} from 'react';
import GuideCard from '../../../../common/cards/GuideCard';
import ReusableComponent from './ReusableComponent';

let renderGuideCard = () => {
    return(
        <GuideCard
            dsc={"Exciting news! This section is currently under development. Stay tuned as we prepare to unveil it soon. Get ready to access and start using this new section in no time. We can't wait to share it with you!"}
            buttonText={"Add member"}
        />
    )
}

const tabConfig = [
    {
        "tabName": "Email Communication",
        "nested": true,
        "data": [
            {
                "tabName": "InterestRate",
                "title": "Make title accordingly",
                "desc": "Make description accordingly",
                "component": "InterestRate"
            },
            {
                "tabName": "Birthday",
                "title": "Managing Birthday Communications",
                "desc": "Short description of this page",
                "component": "BirthdayCommunication"
            },
            {
                "tabName": "LetterGroup",
                "title": "Creating a New Letter Group",
                "desc": "Short description of this page",
                "component": "LetterGroup"
            },
            {
                "tabName": "EmailQueue",
                "title": "Email Queue Status Monitoring and Troubleshooting",
                "desc": "Short description of this page",
                "component": "EmailQueue"
            },

            {
                "tabName": "EmailServer",
                "title": "Setting Up Global Email Server Details",
                "desc": "Short description of this page",
                "component": "EmailServer"
            },
            {
                "tabName": "EmailTemplates",
                "title": "Setting Up and Managing Email Templates",
                "desc": "Short description of this page",
                "component": "EmailTemplates"
            }
            // Add more tabs here if needed
        ]
    },
    {
        "tabName": "SMS & Telephony",
        "nested": false,
        "data": [
            {
                "tabName": "NewTemplates",
                "title": "User Profile Edit",
                "desc": "Short description of this page",
                "component": "NewTemplates"
            },
            {
                "tabName": "SentMessages",
                "title": "SMS Invoices",
                "desc": "Short description of this page",
                "component": "SentMessages"
            },
            {
                "tabName": "TelephonyProvider",
                "title": "New User Setup",
                "desc": "Short description of this page",
                "component": "TelephonyProvider"
            }
        ]
    },
    {
        "tabName": "Fact-Find",
        "nested": false,
        "data": [
            {
                "tabName": "Basics",
                "title": "Basics",
                "desc": "Short description of this page",
                "component": "Basics"
            },
            {
                "tabName": "Customization",
                "title": "Customization",
                "desc": "Short description of this page",
                "component": "Customization"
            },
            {
                "tabName": "Guide",
                "title": "Guide",
                "desc": "Short description of this page",
                "component": "Guide"
            },
            {
                "tabName": "PostcodeLookup",
                "title": "PostcodeLookup",
                "desc": "Short description of this page",
                "component": "PostcodeLookup"
            },
        ]
    }
    // Add more sections here following the same format
];

const General = () => {
    return(
        <ReusableComponent
            title="Communication"
            description="Comprehensive tools for managing client relations, action notes, cancellations, and case statuses."
            tabConfig={tabConfig}
        />
    )
};

export default General;


