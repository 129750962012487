import React, {useEffect, useState} from "react";
import PageTitle from "../../../../layouts/PageTitle";
import {add_modules_api, get_modules_api} from "../../../../../api/modules";
import { handleFormInput } from "../../../../../common/form";
import TableView from "./TableView";
const ModuleList = () => {
    const [formData, setFormData] = useState({});
    const [modules, setModules] = useState([]);

    const handleBlur = (e, payload = {}) => {
        let updatedFormData = handleFormInput({ e, formData, payload });
        setFormData(updatedFormData);
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        await add_modules_api({formData});
        await getAllModules();
    };

    const getAllModules = async () => {
        const dt = await get_modules_api();
        console.log("dt", dt);
        setModules(dt);
    };

    useEffect(() => {
        getAllModules();
    }, []);

    return (
        <>
            <PageTitle activeMenu="Modules" motherMenu="Admin" />
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Add Module</h4>
                    </div>
                    <div className="card-body">
                        <form onSubmit={(e) => submitHandler(e)}>
                            <div className="row">
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Module Flag*</label>
                                        <input
                                            type="text"
                                            name="moduleFlag"
                                            className="form-control"
                                            placeholder="Module Flag"
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Module Name*</label>
                                        <input
                                            type="text"
                                            name="moduleName"
                                            className="form-control"
                                            placeholder="Module Name"
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 mb-2">
                                    <button type="submit" className="btn btn-primary mb-2 float-right">
                                        Add
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="row">
                {modules.length > 0 &&
                    <TableView
                        title={"Users"}
                        reloadDB={() => getAllModules()}
                        data={modules}
                        actionShow={false}
                    />
                }
            </div>
        </>
    );
};

export default ModuleList;
