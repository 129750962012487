import React, {useState} from 'react';
import GuideCard from '../../../../common/cards/GuideCard';
import ReusableComponent from './ReusableComponent';

let renderGuideCard = () => {
    return(
        <GuideCard
            dsc={"Exciting news! This section is currently under development. Stay tuned as we prepare to unveil it soon. Get ready to access and start using this new section in no time. We can't wait to share it with you!"}
            buttonText={"Add member"}
        />
    )
}

const tabConfig = [
    {
        "tabName": "Diary",
        "nested": true,
        "data": [
            {
                "tabName": "NewStatuses",
                "title": "Adding Branches to CRM",
                "desc": "Short description of this page",
                "component": "NewStatuses"
            },
            {
                "tabName": "EntryTypes",
                "title": "Managing Action Note Types in CRM",
                "desc": "Short description of this page",
                "component": "EntryTypes"
            }
        ]
    },
    {
        "tabName": "Docs",
        "nested": false,
        "data": [
            {
                "tabName": "AddToLib",
                "title": "User Profile Edit",
                "desc": "Short description of this page",
                "component": "AddToLib"
            },
            {
                "tabName": "FileCats",
                "title": "SMS Invoices",
                "desc": "Short description of this page",
                "component": "FileCats"
            },
            {
                "tabName": "Docs-Edit",
                "title": "New User Setup",
                "desc": "Short description of this page",
                "component": "DocsEdit"
            },
            {
                "tabName": "WordTemplates",
                "title": "New User Setup",
                "desc": "Short description of this page",
                "component": "WordTemplates"
            },
            {
                "tabName": "LetterTemplates",
                "title": "New User Setup",
                "desc": "Short description of this page",
                "component": "LetterTemplates"
            }
        ]
    },
    {
        "tabName": "Custom Fields & Automations",
        "nested": false,
        "data": [
            {
                "tabName": "AutoRules",
                "title": "Simple Tasks Setup",
                "desc": "Short description of this page",
                "component": "AutoRules"
            },
            {
                "tabName": "NewCustField",
                "title": "New Stage",
                "desc": "Short description of this page",
                "component": "NewCustField"
            },
            {
                "tabName": "CustCustFields",
                "title": "Stage Actions",
                "desc": "Short description of this page",
                "component": "CustCustFields"
            },
            {
                "tabName": "CustSearchView",
                "title": "Criteria for Moving to a Stage",
                "desc": "Short description of this page",
                "component": "CustSearchView"
            },
            {
                "tabName": "SpreadMaps",
                "title": "Task Statuses",
                "desc": "Short description of this page",
                "component": "SpreadMaps"
            }
        ]
    },
    {
        "tabName": "Participants",
        "nested": false,
        "data": [
            {
                "tabName": "NewClub",
                "title": "Simple Tasks Setup",
                "desc": "Short description of this page",
                "component": "NewClub"
            },
            {
                "tabName": "PartCustField",
                "title": "New Stage",
                "desc": "Short description of this page",
                "component": "PartCustField"
            },
            {
                "tabName": "PartFirm",
                "title": "Stage Actions",
                "desc": "Short description of this page",
                "component": "PartFirm"
            },
            {
                "tabName": "PartNetwork",
                "title": "Criteria for Moving to a Stage",
                "desc": "Short description of this page",
                "component": "PartNetwork"
            },
            {
                "tabName": "PartViewPerms",
                "title": "Task Statuses",
                "desc": "Short description of this page",
                "component": "PartViewPerms"
            }
        ]
    },
    {
        "tabName": "Policies",
        "nested": false,
        "data": [
            {
                "tabName": "PolicyType",
                "title": "Task Statuses",
                "desc": "Short description of this page",
                "component": "PolicyType"
            },
            {
                "tabName": "LoanPurpose",
                "title": "Task Statuses",
                "desc": "Short description of this page",
                "component": "LoanPurpose"
            },
            {
                "tabName": "PaymentMethod",
                "title": "Task Statuses",
                "desc": "Short description of this page",
                "component": "PaymentMethod"
            },
            {
                "tabName": "TitleOptions",
                "title": "Task Statuses",
                "desc": "Short description of this page",
                "component": "TitleOptions"
            }
        ]
    },
    // Add more sections here following the same format
];

const DataManagement = () => {
    return(
        <ReusableComponent
            title="Data Management"
            description="Comprehensive tools for managing client relations, action notes, cancellations, and case statuses."
            tabConfig={tabConfig}
        />
    )
};

export default DataManagement;


