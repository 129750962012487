import FormComponent from '../../../../../../common/formUI/FormComponent';

const formData = [
    {
        fieldName: "Description",
        labelName: "Description",
        placeholder: "Description",
        fieldType: "text",
        onChange: false,
        dynamicValue: false,
    },
    {
        fieldName: "Description",
        labelName: "Move to Stage on Case Abandoned",
        placeholder: "Move to Stage on Case Abandoned",
        fieldType: "select",
        onChange: false,
        dynamicValue: false,
        options: [
            {
                value: "stage1",
                text: "Stage 1"
            },
            {
                value: "stage2",
                text: "Stage 2"
            }
        ]
    }
]


const CaseAbandoned = () => {

    return (
        <>
            <FormComponent
                submitButtonText="Add"
                formData={formData}
            />
        </>
    )
}

export default CaseAbandoned;