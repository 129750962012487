import {postData, getData} from "../../import";
import swal from "sweetalert";

let url;
if (process.env.REACT_APP_ENV === "development") {
    url = process.env.REACT_APP_DEV_API;
} else {
    url = process.env.REACT_APP_PRO_API;
}


const add_modules_api = async ({formData}) => {
    const { moduleFlag, moduleName } = {...formData}
    try {
        const data = { moduleFlag, moduleName };
        const response = await postData(url + 'module/add-modules', data);
        if (response) {
            await swal(
                "Warning!",
                "Module added",
                "success"
            )
            return true;
        }
    } catch (error) {
        console.error(error);
        throw new Error("Failed to add module");
    }
};

const get_modules_api = async () => {
    try {
        return await getData(url + 'module/get-modules');
    } catch (error) {
        console.error(error);
        throw new Error("Failed to get modules");
    }
};


const add_submodule_api = async (formData) => {
    try {
        // Make the API call to add a submodule
        const response = await postData(url + "module/submodules", formData);

        if (response) {
            await swal(
                "Warning!",
                "Sub module added",
                "success"
            )
            return true;
        }
    } catch (error) {
        console.error("Error adding submodule:", error);
        throw error;
    }
};

const get_submodules_api = async (moduleId) => {
    try {
        return await getData(url + `module/submodules/${moduleId}`);
    } catch (error) {
        console.error("Error fetching submodules:", error);
        throw error;
    }
};

export { add_modules_api, get_modules_api, add_submodule_api, get_submodules_api };