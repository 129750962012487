import FormComponent from '../../../../../../common/formUI/FormComponent';
import TableView from '../../../../../../common/table';

const formData = [
    {
        fieldName: "Branch Name",
        labelName: "Branch Name",
        placeholder: "Branch Name",
        fieldType: "text",
        onChange: false,
        dynamicValue: false,
    },
    {
        fieldName: "Branch Address",
        labelName: "Branch Address",
        placeholder: "Branch Address",
        fieldType: "text",
        onChange: false,
        dynamicValue: false,
    }
]

const tableData = [
    {id: '1', col: 'col 1', colTwo: 'col 11', colThree: 'col 111'},
    {id: '2', col: 'col 2', colTwo: 'col 22', colThree: 'col 222'},
    {id: '3', col: 'col 3', colTwo: 'col 33', colThree: 'col 333'},
    {id: '4', col: 'col 4', colTwo: 'col 44', colThree: 'col 444'},
    {id: '5', col: 'col 5', colTwo: 'col 55', colThree: 'col 555'},
];

const Branches = () => {

    return (
        <>
            <FormComponent
                formData={formData}
            />

            <TableView
                tableData={tableData}
            />
        </>
    )
}

export default Branches;