import React from "react";
import { Table } from "react-bootstrap";
import Moment from "moment";

const columns = ["Module Flag", "Module Name", "Created Date", "Updated Date"];

const TableView = ({ title, data }) => {
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{title}</h4>
                </div>
                <div className="card-body">
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                                <thead>
                                <tr role="row">
                                    <th>SR.</th>
                                    {columns.map((column, index) => (
                                        <th key={index}>{column}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((module, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{module.moduleFlag}</td>
                                        <td>{module.moduleName}</td>
                                        <td>
                                            {Moment(module.created_date).locale("en").format("DD/MM/YYYY")}
                                        </td>
                                        <td>
                                            {Moment(module.updated_date).locale("en").format("DD/MM/YYYY")}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default TableView;
