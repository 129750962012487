import FormComponent from '../../../../../../common/formUI/FormComponent';

const formData = [
    {
        fieldName: "Action Description",
        labelName: "Action Description",
        placeholder: "Action Description",
        fieldType: "text",
        onChange: false,
        dynamicValue: false,
    }
]


const ActionNotes = () => {

    return (
        <>
            <FormComponent
                formData={formData}
            />
        </>
    )
}

export default ActionNotes;